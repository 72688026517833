import "./assets/css/style.css"
import "./assets/css/bootstrap-icons.css"
import Header from "./components/Header"
import Banner from "./components/Banner"
import Features from "./components/Features"
import HowItWorks from "./components/HowItWorks";
import TwoWayMessage from "./components/TwoWayMessage";
import Footer from "./components/Footer";
import Integrations from "./components/Integrations";
import { useEffect } from 'react'

function App() {

  useEffect(() => {
    let scripts = [
      // { src: "vendor/purecounter/purecounter.js" },
      { src: "https://unpkg.com/aos@2.3.1/dist/aos.js" },
      {
        src: "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js",
        integrity: "sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p",
        crossorigin: "anonymous"
      },
      { src: "vendor/glightbox/js/glightbox.min.js" },
      { src: "https://cdnjs.cloudflare.com/ajax/libs/jquery.isotope/3.0.6/isotope.pkgd.min.js" },
      { src: "https://cdnjs.cloudflare.com/ajax/libs/Swiper/7.3.0/swiper-bundle.min.js" },
      // { src: "vendor/php-email-form/validate.js" },
      { src: "js/main.js" }
    ]
    scripts.forEach(item => {
      const script = document.createElement("script")
      script.src = item.src
      if(item?.integrity){
        script.integrity = item.integrity
      }
      if(item?.crossorigin){
        script.crossorigin = item.crossorigin
      }
      script.async = true
      document.body.appendChild(script)
    })
  }, [])

  return (
    <>
      <Header />
      <Banner />
      <main id="main">
        <Features />
        <HowItWorks />
        <TwoWayMessage />
        <Integrations />
      </main>
      <Footer />
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
    </>
  );
}

export default App;
