import React from 'react'

const Features = () => {
    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <h2>Features</h2>
                    <p>Redtie is the smart way to connect with your customers. With the features below, you can have better engagement with them.</p>
                </header>
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box purple_clr">
                            <img src={ require("../assets/img/features/redtie-feature-attachment.png")} className="img-fluid mb-3" alt="" />
                            <h3>Attachments</h3>
                            <p>Now share any type files – documents, images, videos and audios over a text message just as on email.</p>
                            <a href="https://getredtie.com/send-pdf-via-text-message/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box orange">
                            <img src={ require("../assets/img/features/redtie-feature-auto-responder.png")} className="img-fluid mb-3" alt="" />
                            <h3>Auto Responder</h3>
                            <p>Set predefined messages as automatic responses for inbound messages, voice calls, birth day greetings etc.</p>
                            <a href="https://getredtie.com/autoresponders/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box purple_clr">
                            <img src={ require("../assets/img/features/redtie-feature-two-way-messaging.png")} className="img-fluid mb-3" alt="" />
                            <h3>Two-Way Messaging</h3>
                            <p>Engage with your customers effectively with Redtie’s transactional messaging suite and give them a way to reach out to you. Stay close with your customers.</p>
                            <a href="https://getredtie.com/two-way-text-messaging/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box orange">
                            <img src={ require("../assets/img/features/redtie-feature-message-archiving-1.png")} className="img-fluid mb-3" alt="" />
                            <h3>Message Archiving</h3>
                            <p>Retrieve your deleted messages for any future need as and when required, all you need is to just turn this feature ON and claim the deleted messages anytime.</p>
                            <a href="https://getredtie.com/message-archiving/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box purple_clr">
                            <img src={ require("../assets/img/features/redtie-feature-integrations.png")} className="img-fluid mb-3" alt="" />
                            <h3>Integrations</h3>
                            <p>We love working other SaaS products, for now stay connected with your Gmail &amp; Slack contacts, we are working to partner with other SaaS products soon.</p>
                            <a href="https://getredtie.com/google-calendar-integration-redtie/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box orange">
                            <img src={ require("../assets/img/features/redtie-feature-automation-01.png")} className="img-fluid mb-3" alt="" />
                            <h3>Automation</h3>
                            <p>Engage with your customers effectively with Redtie’s transactional messaging suite and give them a way to reach out to you. Stay close with your customers.</p>
                            <a href="https://getredtie.com/text-message-automation/" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className="col-sm-12 d-flex justify-content-center">
                        <div data-aos="fade-up">
                            <div className="text-center text-lg-start">
                                <a href="https://app.redtie.co/" className="btn-get-started-section d-inline-flex">
                                    <span>Try Us Free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Features