import React from "react";

const Header = () => {
    return (
        <header id="header" className="header fixed-top">
          <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a href="index.html" className="logo d-flex align-items-center">
              <img src={ require("../assets/img/logo.png")} alt="" />
            </a>
            <nav id="navbar" className="navbar">
              <ul>
                <li><a className="nav-link scrollto active" href="#">Home</a></li>
                <li><a className="nav-link scrollto" href="#">Pricing</a></li>
                <li><a className="nav-link scrollto" href="#">Try Us Free</a></li>
                <li><a className="nav-link scrollto" href="#">Login</a></li>       
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>
          </div>
        </header>
      );
}

export default Header;
